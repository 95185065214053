import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "gatsby";
import styles from "./TextAndImageBanner.module.scss";

interface TextAndImageBannerProps
{
    data: any;
}

const TextAndImageBanner = (props: PropsWithChildren<TextAndImageBannerProps>): ReactElement =>
{
    const {
        banner_background,
        banner_title,
        banner_subtitle,
        banner_text,
        banner_text_alignment,
    } = props.data;

    const setTextAlignment = (): string =>
    {
        switch (banner_text_alignment)
        {
            case "Center":
                return styles.alignedCenter;
            case "Right":
                return styles.alignedRight;
            default:
                return styles.alignedLeft;
        }
    };

    return (
        <div
            className={`${styles.textAndImageBanner}`}
        >
            <div className={`${styles.textContainer} ${setTextAlignment()}`}>
                {banner_title[0] && <h2>{banner_title[0]?.text}</h2>}

                {banner_subtitle[0] && <h3>{banner_subtitle[0]?.text}</h3>}

                {banner_text.length &&
                    banner_text.map((x: any, i: number) => (
                        <p key={i}>{x.text}</p>
                    ))}

            </div>

            <div
                className={styles.backgroundContainer}
                style={{ backgroundImage: `url(${banner_background.url})` }}
            />
        </div>
    );
};

export default TextAndImageBanner;
